import FlashLib from 'flashlib';
import * as PIXI from 'pixi.js';
import WebFont from 'webfontloader';

import Assets from '../assets/flashlib/exported/FlashLibAssets';
import Meta from '../assets/flashlib/exported/FlashLib';
import ControllerUI from './ui/ControllerUI';

export default class Game {
  constructor() {
    this.app = null;
    this.compiled = false;

    this.fontsLoaded = false;
    this.assetsLoaded = false;
    this.soundsLoaded = false;
    this.animationsLoaded = false;
    this.gameInitilized = false;

    this.config = {
      width: 1165,
      height: 1031
    };

    this.start();
  }

  start() {
    this.applicationOptions = {
      // antialias: true,
      // backgroundColor: 0x6fc1f6,
      // legacy: true,
      transparent: true,
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      resolution: this.isRetinaDisplay() ? 1 : 1,
      autoResize: true
    };
    this.app = new PIXI.Application(this.applicationOptions);
    this.onResize();

    this.container = document.createElement('div');
    this.container.id = 'coinGameOPContainer';
    document.body.appendChild(this.container);
    this.container.appendChild(this.app.view);
    window.addEventListener('resize', this.onResize.bind(this));
    window.addEventListener('orientationchange', this.onResize.bind(this));

    this.loadAssets();
    this.loadFonts();
    this.loadAnimations();
    // PIXI.Loader.shared.load();
  }

  loadAssets() {
    PIXI.Loader.shared.baseUrl = './';
    PIXI.Loader.shared.add('FlashLibCoin', Meta, 'json');
    PIXI.Loader.shared.add('FlashLibAssetsCoin', Assets, 'json');
    PIXI.Loader.shared.onComplete.add(() => {
      console.log('Assets loaded');
      this.assetsLoaded = true;
      this.tryInitGame();
    }, this);
    PIXI.Loader.shared.onError.add(this.onLoadingError.bind(this));
  }

  loadFonts() {
    WebFont.load({
      custom: {
        families: ['lobster', 'SegoeUI'],
      },
      fontinactive: console.error,
      active: () => {
        console.log('Fonts loaded');
        this.fontsLoaded = true;
        this.tryInitGame();
      },
      inactive: this.onLoadingError.bind(this),
      timeout: 60000
    });
  }

  onLoadingError(err) {
    console.error(err);
    window.OPWrapperService.showError(window.OPWrapperService.errors.ASSETS_ERROR.CODE);
    PIXI.Loader.shared.reset();
  }
  loadAnimations() {
    PIXI.Loader.shared.add('coin_animation', '/animations/coin/x2coin.json', 'json');
    PIXI.Loader.shared.onComplete.add(() => {
      console.log('Animations loaded');
      this.animationsLoaded = true;
      this.tryInitGame();
    }, this);
  }

  tryInitGame() {
    if (this._readyForInit()) {
      this.initGame();
    }
  }

  get ready() {
    return this._readyForInit() && this.gameInitilized;
  }

  _readyForInit() {
    return (
      this.fontsLoaded &&
      this.assetsLoaded &&
      this.animationsLoaded
      // this.soundsLoaded &&
    )
  }

  initGame() {
    const main = FlashLib.createItemFromLibrary('main', 'FlashLibCoin');
    main.visible = false;
    this.controllerUI = new ControllerUI(main, this.container);
    this.app.stage.addChild(main);
    this.mainContainer = main;
    this.calculateMainScale();

    this.gameInitilized = true;
  }

  show(minBetAmount, amount, currency, onPlayClicked, onCollectClicked, canTake) {
    this.controllerUI.show(minBetAmount, amount, currency, onPlayClicked, onCollectClicked, canTake);
  }

  showResult(isWin, betAmount) {
    this.controllerUI.showResult(isWin, betAmount);
  }

  onResize() {
    const size = this.calculateScreenSize();
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = document.documentElement.clientHeight * 0.01;
    let vw = document.documentElement.clientWidth * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);

    this.app.view.height = vh * 100 * 2;
    this.app.view.width = vw * 100 * 2;

    this.calculateMainScale();
    this._buildBlocker();

    this.app.view.style.setProperty('height', vh * 100 + 'px');
    this.app.view.style.setProperty('width', vw * 100 + 'px');
  };

  //todo: move to another place
  isRetinaDisplay() {
    let result = false;
    if (window.matchMedia) {
      let mq = window.matchMedia('only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)');
      result = (mq && mq.matches || (window.devicePixelRatio > 1));
    }
    return result;
  }

  calculateMainScale() {
    if (!this.mainContainer) return;
    const scaleX = this.app.view.width / this.config.width;
    const scaleY = this.app.view.height / this.config.height;
    const scale = Math.min(Math.min(scaleX, scaleY), 2);
    this.mainContainer.scale.set(scale);
    this.mainContainer.pivot.set(this.mainContainer.width / scale / 2, this.mainContainer.height / scale / 2);
    this.mainContainer.x = this.app.view.width / 2;
    this.mainContainer.y = this.app.view.height / 2;
  }

  calculateScreenSize() {
    // const resolutionCoef = 2.16666;
    const resolutionCoef = this.config.width / this.config.height;
    const doc = document.documentElement;
    const width = doc.clientWidth || window.innerWidth;
    const height = doc.clientHeight || window.innerHeight;

    const calcWidth = height > width / resolutionCoef ? width : height * resolutionCoef;
    let result = {
      'width': calcWidth,
      'height': height > width / resolutionCoef ? calcWidth / resolutionCoef : height,
    };
    return result;
  }

  _buildBlocker() {
    if (this.blocker)
      this.blocker.destroy();

    this.blocker = new PIXI.Graphics();
    this.blocker.beginFill('0x000000');
    this.blocker.drawRect(0, 0, this.app.view.width, this.app.view.height);
    this.blocker.alpha = 0.8;
    this.app.stage.addChildAt(this.blocker, 0);
  }
}
